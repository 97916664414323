import React, { useRef, useState, useEffect } from 'react';

import './App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyAkCPCIo7LGiqYML7Y-IsEZnBy7H7PEBao",
  authDomain: "classchat-login.firebaseapp.com",
  projectId: "classchat-login",
  storageBucket: "classchat-login.appspot.com",
  messagingSenderId: "628782502391",
  appId: "1:628782502391:web:a9bdd9af7368f63147def2",
  measurementId: "G-S92JTC27WW"
})

const auth = firebase.auth();
const firestore = firebase.firestore();
// eslint-disable-next-line
const analytics = firebase.analytics();

window.onload = checkSignInScreen;
auth.onAuthStateChanged(checkSignInScreen);

function checkSignInScreen() {
  const user = auth.currentUser;
  const form = document.querySelector('form');
  const input = document.querySelector('input[placeholder="Message"]');
  const button = document.querySelector('button[type="submit"]');

  if (user) {
    document.body.classList.remove('signed-out'); // Remove signed-out class when user is signed in
    if (form) {
      form.classList.remove('signed-out'); // Remove signed-out class from form
      form.style.display = 'block';
    }
    if (input) input.style.display = 'block';
    if (button) button.style.display = 'block';
  } else {
    document.body.classList.add('signed-out'); // Add signed-out class when user is signed out
    if (form) {
      form.classList.add('signed-out'); // Add signed-out class to form
      form.style.display = 'block'; // Ensure it is displayed
    }
    if (input) input.style.display = 'none';
    if (button) button.style.display = 'none';
  }
}




function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <h1>ClassChat</h1>
        <SignOut />
      </header>

      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <div className="sign-in-container">
      <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
      <div className="sign-in-shadow"></div> {/* Shadow element */}
    </div>
  );
}




function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}




function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt', 'desc').limit(25);
  const [messages] = useCollectionData(query, { idField: 'id' });
  const [formValue, setFormValue] = useState('');

  const sendMessage = async (e) => {
    e.preventDefault();
    const { uid, photoURL, displayName } = auth.currentUser; 
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName,
    });
    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (dummy.current) {
      dummy.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <>
      <main>
      {messages && [...messages].reverse().map(msg => (
  <ChatMessage key={msg.id} message={msg} />
))}
        <span ref={dummy}></span>
      </main>
      {auth.currentUser && (
        <form onSubmit={sendMessage}>
          <input 
            value={formValue} 
            onChange={(e) => setFormValue(e.target.value)} 
            placeholder="Message" 
          />
          <button 
            type="submit" 
            disabled={!formValue} 
            className={formValue.trim() !== '' ? 'active-button' : ''}
          >
            Enter
          </button>
        </form>
      )}
    </>
  );
}



function ChatMessage(props) {
  const { text, uid, photoURL, displayName, createdAt } = props.message;

  const [isHovered, setIsHovered] = useState(false);
  const [showTimestamp, setShowTimestamp] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const fadeTimeoutRef = useRef(null); // Ref to track fade timeout
  const hideTimeoutRef = useRef(null); // Ref to track hide timeout

  const isSentMessage = uid === auth.currentUser.uid;
  const messageClass = isSentMessage ? 'sent' : 'received';

  // Convert Firestore timestamp to readable hour and minute format
  const messageTime = createdAt
    ? new Date(createdAt.seconds * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    : '';

  const handleMouseEnter = () => {
    // Clear any pending fade or hide timeouts
    if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);
    if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);

    setIsHovered(true);
    setShowTimestamp(true); // Show timestamp immediately on hover

    // Delay to trigger fade-in
    fadeTimeoutRef.current = setTimeout(() => {
      setFadeIn(true);
    }, 10);
  };

  const handleMouseLeave = () => {
    // Clear fade-in timeout if it hasn't triggered yet
    if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);

    setIsHovered(false);
    setFadeIn(false); // Trigger fade-out

    // Delay hiding the timestamp to allow fade-out animation
    hideTimeoutRef.current = setTimeout(() => {
      setShowTimestamp(false);
    }, 500); // Match fade-out duration
  };

  return (
    <div 
      className={`message ${messageClass} ${isHovered ? 'highlight' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isSentMessage && (
        <div className="message-meta">{displayName || 'Anonymous'}</div>
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSentMessage ? 'flex-end' : 'flex-start' }}>
        {!isSentMessage && (
          <img
            src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'}
            alt="Avatar"
          />
        )}
        <p>{text}</p>
        {isSentMessage && (
          <img
            src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'}
            alt="Avatar"
          />
        )}
      </div>

      {/* Show timestamp with smooth fade in and out */}
      {showTimestamp && (
        <div className={`message-time ${fadeIn ? 'fade-in' : 'fade-out'}`}>
          {messageTime}
        </div>
      )}
    </div>
  );
}



export default App;